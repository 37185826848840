
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ParitySettingsService, { ParitySettingsServiceS } from '@/modules/cars/modules/parity/parity-settings.service';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import CarHeader from '@/modules/cars/components/car-header.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import BrokersTableInfo from '../components/brokers/brokers-table-info.vue';
import KpiTabs from '../components/brokers/kpi-tabs.vue';
import BrokersTable from '../components/brokers/brokers-table.vue';
import PerformanceBrokers from '../components/brokers/performance-brokers.vue';
import BrokersActions from '../components/brokers/brokers-actions.vue';

@Component({
    components: {
        PageWrapper,
        CarHeader,
        KpiTabs,
        BrokersTableInfo,
        BrokersTable,
        PerformanceBrokers,
        BrokersActions,
    },
})
export default class BrokersPage extends Vue {
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;
    @Inject(UserServiceS) userService!: UserService;
    @Inject(CarsFiltersServiceS) carsFilterService!: CarsFiltersService;

    mounted() {
        // if (!this.userService.currentCompany) {
        //     return;
        // }
        // if (this.userService.currentCompany.toLowerCase() === 'rentalcars') {
        //     this.$router.push('/');
        // }
    }
}
