
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ParityCountryFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-country-filter.vue';
import ParityPickUpCityFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-pick-up-city-filter.vue';
import ParityDataSourcesFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-data-sources-filter.vue';
import PARITY_FILTER from '@/modules/cars/modules/parity/constants/parity-filter.constant';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';

@Component({
    components: {
        ParityCountryFilter,
        ParityPickUpCityFilter,
        ParityDataSourcesFilter,
    },
})
export default class BrokersActions extends Vue {
    @Inject(CarsFiltersServiceS) carsFilterService!: CarsFiltersService;

    get activeFilters(): PARITY_FILTER[] {
        return Object.entries(PARITY_FILTER).map(([key, value]) => value);
    }

    isCountry(filterName: PARITY_FILTER) { return filterName === PARITY_FILTER.COUNTRY; }
    isPickup(filterName: PARITY_FILTER) { return filterName === PARITY_FILTER.PICKUP; }
    isDataSourcesFilter(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.DATA_SOURCES && this.carsFilterService.isBrokerMode;
    }
}
