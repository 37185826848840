
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { Inject } from 'inversify-props';
import { ChartData, ChartOptions } from 'chart.js';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import BROKERS_COLORS from '@/modules/cars/modules/parity/constants/brokers-colors';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import ParityService, { ParityServiceS } from '../../parity.service';

@Component({
    components: {
        CustomGraph, LoaderWrapper,
    },
})
export default class PerformanceGraph extends Vue {
    @Inject(ParityServiceS) private parityService!: ParityService;
    @Inject(CarsFiltersServiceS) carsFilterService!: CarsFiltersService;

    private logos = [
        'expedia',
        'autoeurope',
        'rentalcars',
        'hertz',
        'alamo',
        'avis',
        'dollar',
        'budget',
        'europcar',
        'sixt',
        'thrifty',
        'virtuo',
    ];

    private hiddenLegends: string[] = [];

    handleLegendClick(legend: any) {
        const { length } = this.hiddenLegends;
        this.hiddenLegends = this.hiddenLegends.filter(item => item !== legend.name);
        if (length === this.hiddenLegends.length) {
            const { name } = legend;
            this.hiddenLegends.push(name);
        }
    }

    get filteredDates() {
        const maxTrendLength = 90;
        const { brokersPerformanceData } = this;
        if (!brokersPerformanceData) {
            return null;
        }

        const brokers = Object.keys(brokersPerformanceData);

        let dates: string[] = [];
        brokers.forEach(item => {
            const obj = brokersPerformanceData[item];
            const brokerDate = Object.keys(obj);
            dates.push(...brokerDate);
        });
        dates = [...new Set(dates)];
        const today = moment().format('YYYY-MM-DD');
        return dates.filter(item => moment(item).isAfter(today) || moment(item).isSame(today))
            .sort((a, b) => {
                if (moment(a).isBefore(b)) {
                    return -1;
                }
                return 1;
            }).slice(0, maxTrendLength);
    }

    get legends() {
        if (!this.brokersPerformanceData) return null;

        const brokers = Object.keys(this.brokersPerformanceData);
        let brokerColorIndex = 0;

        return brokers.map(broker => {
            const brokerName = broker;
            const isDisabled = this.hiddenLegends.find(legend => legend === brokerName);
            const color = PROVIDER_COLORS[brokerName] || BROKERS_COLORS[brokerColorIndex];
            brokerColorIndex++;
            return {
                name: brokerName,
                borderColor: isDisabled ? '#b2bdbd' : color,
                image: this.getImage(brokerName),
            };
        });
    }

    getImage(name: string) {
        const images = require.context('@/modules/common/assets/broker-logos', false, /\.png$|\.jpg$|\.svg$/);
        const found = this.logos.find(item => item === name.toLocaleLowerCase());
        if (!found) {
            return images('./placeholder.svg');
        }
        return images(`./${found}.svg`);
    }

    get numberDates() {
        const { filteredDates } = this;
        if (!filteredDates) return null;

        return filteredDates.length;
    }

    get brokersPerformanceData() {
        const { brokersPerformanceData } = this.parityService;
        const { currentChainCode, isBrokerMode } = this.carsFilterService;

        if (currentChainCode && isBrokerMode && brokersPerformanceData) {
            return Object.keys(brokersPerformanceData).reduce((prev, key) => {
                const data = { ...prev };
                const [, newKey] = key.split(',');
                if (!data[newKey]) {
                    data[newKey] = brokersPerformanceData[key];
                }
                return data;
            }, { } as { [index: string]: { [index: string]: number } });
        }

        return brokersPerformanceData;
    }

    get chartData(): ChartData | null {
        const { filteredDates, hiddenLegends } = this;
        const { brokersPerformanceData } = this;

        if (!filteredDates || !brokersPerformanceData) {
            return null;
        }

        const labels = filteredDates.map(item => moment(item).format('DD/MM'));
        const brokers = Object.keys(brokersPerformanceData);
        const newDataSets: { label: string; data: any[]; fill: boolean; }[] = [];
        let brokerColorIndex = 0;
        brokers.forEach(brokerName => {
            const dateValues: any[] = [];
            filteredDates.forEach(date => {
                const value: any = brokersPerformanceData[brokerName][date] ? brokersPerformanceData[brokerName][date] : null;
                dateValues.push(Math.round(value * 100));
            });
            const isDisabled = hiddenLegends.find(legend => legend === brokerName);
            const info = {
                label: brokerName,
                data: dateValues,
                fill: false,
                borderColor: PROVIDER_COLORS[brokerName] || BROKERS_COLORS[brokerColorIndex],
            };
            if (!isDisabled) {
                newDataSets.push(info);
            }
            brokerColorIndex++;
        });
        const datasets = newDataSets;

        if (labels.length === 0 && datasets.length === 0) {
            return null;
        }

        return {
            labels,
            datasets,
        };
    }

    get options(): ChartOptions {
        // @ts-ignore
        const brokers = Object.keys(this.brokersPerformanceData);
        const values: any[] = [];
        brokers.forEach((item: any) => {
            // @ts-ignore
            const value = Object.values(this.brokersPerformanceData[item]).map(val => Math.round(val * 100));
            values.push(...value);
        });
        return {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
                point: {
                    radius: 0,
                },
            },
            tooltips: {
                intersect: false,
            },
            legend: {
                display: false,
            },
            // @ts-ignore
            scales: {
                yAxes: [{
                    ticks: {
                        max: 100,
                        min: 0,
                        stepSize: 25,
                        padding: 10,
                    },
                    gridLines: {
                        drawOnChartArea: false,
                        drawTicks: false,
                    },
                }],
                xAxes: [{
                    ticks: {
                        padding: 10,
                    },
                    gridLines: {
                        drawOnChartArea: false,
                        drawTicks: false,
                    },
                }],
            },
        };
    }
}
