

import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { Tabs, TabPane } from 'element-ui';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import Map from '@/modules/common/components/map.vue';
import ParityService, { ParityServiceS } from '@/modules/cars/modules/parity/parity.service';
import ParityFiltersService, { ParityFiltersServiceS } from '@/modules/cars/modules/parity/parity-filters.service';
import KpisMapDataModel from '@/modules/cars/modules/parity/models/kpis-map-data.model';

export interface IKpiTabs {
    name: string;
    key: string;
    active: boolean;
}
const tabs: IKpiTabs[] = [
    {
        key: 'top-kpis',
        name: 'top kpi\'s',
        active: true,
    },
    {
        key: 'map',
        name: 'Map',
        active: false,
    },
];

@Component({
    components: {
        LoaderWrapper,
        Map,
        'el-tabs': Tabs,
        'el-tab-pane': TabPane,
    },
})
export default class KpiTabs extends Vue {
    @Inject(ParityServiceS) private parityService!: ParityService;
    @Inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;

    get tabs() {
        return tabs;
    }

    get markers() {
        const { map } = this.parityService;
        const { settings } = this.parityFiltersService;
        const { locations } = settings;
        if (!map || !locations) {
            return [];
        }

        return map.map(item => {
            const location = locations.find(loc => loc.locationId === item.locationCode);
            if (!location) {
                return {
                    position: {
                        lat: null,
                        lng: null,
                    },
                };
            }
            return {
                position: {
                    lat: location.geoLocation[1],
                    lng: location.geoLocation[0],
                },
                icon: this.getIcon(item.L),
                tooltip: this.createTooltip(item),
            };
        }).filter(item => item.position.lat !== 0 && item.position.lng !== 0);
    }

    get center() {
        if (this.markers.length > 0) {
            return this.markers[0].position;
        }
        return {
            lat: 31.771959,
            lng: 35.217018,
        };
    }

    getIcon(loses: number) {
        const images = require.context('@/modules/common/assets/', false, /\.png$|\.jpg$|\.svg$/);

        if (loses === 0) {
            return images('./marker-null.svg');
        }
        if (loses > 0 && loses <= 10) {
            return images('./marker-low.svg');
        }
        if (loses > 10 && loses <= 30) {
            return images('./marker-medium.svg');
        }

        return images('./marker-high.svg');
    }

    get kpis() {
        const { kpis } = this.parityService;
        if (!kpis) {
            return {
                severeIssues: null,
                soldOut: null,
                allocationIssues: null,
            };
        }
        return kpis;
    }

    createTooltip(item: KpisMapDataModel) {
        const name = this.parityFiltersService.getCityByCode(item.locationCode);
        const { L } = item;
        let color = '#E97977';
        if (L === 0) {
            color = '#828E99';
        }
        if (L > 0 && L <= 10) {
            color = '#F9DBD9';
        }
        if (L > 10 && L <= 30) {
            color = '#EEA6A3';
        }
        return `<div class="broker-tooltip">
        <div class="broker-title">
            <div class="broker-name">${name}</div>
            <div class="broker-distribution">BML Distribution</div>
        </div>
        <div class="line" style="background-color: ${color}"></div>
        <div class="broker-inner">
            <div class="broker-row">
                <div class="title">
                    <span class="dot yellow-dot"></span>
                    <span>Beat</span>
                </div>
                <div class="value">${item.B}%</div>
            </div>
            <div class="broker-row">
                <div class="title">
                    <span class="dot green-dot"></span>
                    <span>Meet</span>
                </div>
                <div class="value">${item.M}%</div>
            </div>
            <div class="broker-row">
                <div class="title">
                    <span class="dot red-dot"></span>
                    <span>Lose</span>
                </div>
                <div class="value">${item.L}%</div>
            </div>
            <div class="broker-row">
                <div class="title">
                    <span class="dot grey-dot"></span>
                    <span>Sold Out</span>
                </div>
                <div class="value">${item.A}%</div>
            </div>
        </div>
        <div class="broker-footer">
            <div class="broker-row">
                <div class="title">
                    Total cases
                </div>
                <div class="value">${item.totalCases}</div>
            </div>
        </div>
    </div>`;
    }
}
